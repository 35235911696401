import React from 'react'
import SEO from "../../../../../../components/atoms/seo"

import Layout from '../../../../../../components/organisms/layout'
import Heading from '../../../../../../components/molecules/heading'
import Questionnaire from '../../../../../../components/organisms/questionnaire'
import KeyFeatures from '../../../../../../components/molecules/key-features'

import steps from "../../../../../../data/pages/questionnaire/closet/taboola/product/23/tcw-37/steps.json"
import verifyInformation from "../../../../../../data/pages/questionnaire/closet/taboola/verify.json"
import content from "../../../../../../data/pages/questionnaire/closet/taboola/content.json"

const QuestionnairePage = () => (
  <Layout showBG showFormSecureBanner>
    <SEO title={content.seo.title} />
    <br />
    <br />
    <br />
    <Heading 
        title=''
        subTitle=''
        weight='regular'
    />
    <Questionnaire 
      steps={steps}
      verifyInformation={verifyInformation}
      disclaimer={content.disclaimer}
      onSubmitMessage={content.onSubmit.message}
      thankYouURL={content.onSubmit.thankYouURL}
      formType="Taboola"
      thankYouChatBot
    />
    <KeyFeatures
      features={[
        {
            title: 'Double Lifetime Warranty',
            image: 'icon-1.png',
            imageAlt: ''
        },
        {
            title: 'USA-Made<br/>Materials',
            image: 'icon-2.png',
            imageAlt: ''
        },
        {
            title: 'A New Closet In Less&nbsp;Than&nbsp;1&nbsp;Day',
            image: 'icon-3.png',
            imageAlt: ''
        }
    ]}
    />
  </Layout>
)

export default QuestionnairePage
